import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../src/components/Layout/Layout';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Modi from '../src/components/Modi/Modi';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Tyr from '../src/components/Tyr/Tyr';
import Button from '../src/components/Buttons/Buttons';
import Hod from '../src/components/Hod/Hod';
import Ymir from '../src/components/Ymir/Ymir';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import { Freya } from '../src/components/Freya/Freya';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import Astrid from '../src/components/Astrid/Astrid';
import Gerd from '../src/components/Gerd/Gerd';

import './ficha_mundos.scss';

class ZoneDetails extends React.Component {
  state = {};

  handleMap = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  render() {
    const { pageContext } = this.props;

    const heimdallData = {
      name: this.props.data.allWorldDetailsHeaderBlock.edges[0].node.title,
      image: {
        url: this.props.data.allWorldDetailsImageHeaderBlock.edges[0].node.localImage
          .childImageSharp.fluid,
      },
      map: 'true',
    };

    const modiData = {
      Image:
        'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/009/030/original/pap.png',
      Text: this.props.data.allWorldDetailsHeaderBlock.edges[0].node.subtitle,
      link: this.props.data.allWorldDetailsHeaderBlock.edges[0].node.description,
      button1: {
        cta: tt('/entradas', this.props.pageContext.locale),
        size: 'alone',
        color: 'orange',
        ctaText: tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
      },
    };

    const h2Data1 = {
      title: this.props.data.allWorldDetailsInfo.edges[0].node.rides_title,
      description: this.props.data.allWorldDetailsInfo.edges[0].node.rides_description,
    };
    const h2Data2 = {
      title: this.props.data.allWorldDetailsInfo.edges[0].node.restaurants_title,
      description: this.props.data.allWorldDetailsInfo.edges[0].node.restaurants_description,
    };

    const h2Data3 = {
      title: this.props.data.allWorldDetailsInfo.edges[0].node.shops_title,
      description: this.props.data.allWorldDetailsInfo.edges[0].node.shops_description,
    };
    const h2Data4 = {
      title: this.props.data.allWorldDetailsInfo.edges[0].node.shows_title,
      description: this.props.data.allWorldDetailsInfo.edges[0].node.shows_description,
    };

    const freyaData1 = {
      cards: this.props.data.allRidesSubBlock.edges.map((ride) => ({
        image: ride.node.localImage.childImageSharp.fluid,
        altImage: ride.node.image_alt,
        imgsize: ride.node.imgsize,
        title: ride.node.title,
        description: ride.node.description,
        seeMore: ride.node.seeMore,
        located: ride.node.located,
        disabled: ride.node.disabled,
        features: ride.node.features,
        possibleFilters: ride.node.possibleFilters1 + ride.node.possibleFilters2,
        buttons: {
          cta: ride.node.buttons.cta,
          size: ride.node.buttons.size,
          color: ride.node.buttons.color,
          ctaText: ride.node.buttons.ctaText,
        },
      })),
    };

    const freyaData2 = {
      cards: this.props.data.allRestaurantsSubBlock.edges.map((shop) => ({
        image: shop.node.localImage.childImageSharp.fluid,
        altImage: shop.node.image_alt,
        imgsize: shop.node.imgsize,
        title: shop.node.title,
        description: shop.node.description,
        seeMore: shop.node.seeMore,
        located: shop.node.located.charAt(0).toUpperCase() + shop.node.located.slice(1),
        features: shop.node.features.split(' '),
        disabled: shop.node.disabled,
        possibleFilters: shop.node.possibleFilters1 + shop.node.possibleFilters2,
        buttons: {
          cta: shop.node.buttons.cta,
          size: shop.node.buttons.size,
          color: shop.node.buttons.color,
          ctaText: shop.node.buttons.ctaText,
        },
      })),
    };

    const freyaData3 = {
      cards: this.props.data.allTiendasCardsSubBlock.edges.map((shop) => ({
        image: shop.node.localImage.childImageSharp.fluid,
        altImage: shop.node.image_alt,
        imgsize: shop.node.imgsize,
        title: shop.node.title,
        description: shop.node.description,
        seeMore: shop.node.seeMore,
        located: shop.node.located.charAt(0).toUpperCase() + shop.node.located.slice(1),
        features: shop.node.features.split(' '),
        disabled: shop.node.disabled,
        buttons: {
          cta: shop.node.buttons.cta,
          size: shop.node.buttons.size,
          color: shop.node.buttons.color,
          ctaText: shop.node.buttons.ctaText,
        },
      })),
    };

    const freyaData4 = {
      cards: this.props.data.allShowsSubBlock.edges.map((shop) => ({
        image: shop.node.localImage.childImageSharp.fluid,
        altImage: shop.node.image_alt,
        imgsize: shop.node.imgsize,
        title: shop.node.title,
        description: shop.node.description,
        seeMore: shop.node.seeMore,
        located: shop.node.located.charAt(0).toUpperCase() + shop.node.located.slice(1),
        features: shop.node.features.split(' '),
        disabled: shop.node.disabled,
        buttons: {
          cta: shop.node.buttons.cta,
          size: shop.node.buttons.size,
          color: shop.node.buttons.color,
          ctaText: shop.node.buttons.ctaText,
        },
      })),
    };

    const freyaData5 = {
      title: tt('EXPLORA OTROS MUNDOS', this.props.pageContext.locale),
      isSlider: true,
      cards: this.props.data.allWorldDetailsFreya.edges.map((item) => ({
        image: item.node.localImage.childImageSharp.fluid,
        altImage: item.node.image_alt,
        title: item.node.title,
        description: item.node.description,
        imgsize: 'big',
        buttons: {
          cta: item.node.buttons.cta,
          size: item.node.buttons.size,
          color: item.node.buttons.color,
          ctaText: item.node.buttons.ctaText,
        },
      })),
    };

    const buttons = {
      rides_cta: this.props.data.allWorldDetailsInfo.edges[0].node.rides_cta,
      rides_ctaText: this.props.data.allWorldDetailsInfo.edges[0].node.rides_ctaText,
      restaurants_cta: this.props.data.allWorldDetailsInfo.edges[0].node.restaurants_cta,
      restaurants_ctaText: this.props.data.allWorldDetailsInfo.edges[0].node.restaurants_ctaText,
      shops_cta: this.props.data.allWorldDetailsInfo.edges[0].node.shops_cta,
      shops_ctaText: this.props.data.allWorldDetailsInfo.edges[0].node.shops_ctaText,
      shows_cta: this.props.data.allWorldDetailsInfo.edges[0].node.shows_cta,
      shows_ctaText: this.props.data.allWorldDetailsInfo.edges[0].node.shows_ctaText,
    };

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allWorldDetailsSeoData.edges[0].node._0.title}
          description={this.props.data.allWorldDetailsSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} handleMap={this.handleMap} />
          <div className="general-index">
            <div className="zone-content">
              <Modi data={modiData} />
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                options={this.props.pageContext.options}
                title={this.props.data.allWorldDetailsBreadCrumb.edges[0].node.name}
              />
              <Hod
                data={this.props.data.allWorldDetailsRichData.edges[0].node.rich_markup}
                url={this.props.pageContext.url}
                img={
                  this.props.data.allWorldDetailsImageHeaderBlock.edges[0].node.localImage
                    .childImageSharp.fluid
                }
              />
              <BlueArrow />
              <Astrid
                pageContext={this.props.pageContext}
                handleMap={this.handleMap}
                modal={this.state.showModal}
              />

              <div className="first-grey-content">
                <H2 data={h2Data1} />
                <Freya data={freyaData1} />
                <Button
                  link={buttons.rides_cta}
                  size="alone"
                  color="gris"
                  text={buttons.rides_ctaText}
                />
              </div>
              <div className="second-white-content">
                <H2 data={h2Data2} />
                <Freya data={freyaData2} />
                <Button
                  link={buttons.restaurants_cta}
                  size="alone"
                  color="gris"
                  text={buttons.restaurants_ctaText}
                />
              </div>
              <div className="third-grey-content">
                <H2 data={h2Data3} />
                <Freya data={freyaData3} />
                <Button
                  link={buttons.shops_cta}
                  size="alone"
                  color="gris"
                  text={buttons.shops_ctaText}
                />
              </div>
              <div className="fourth-white-content">
                <H2 data={h2Data4} />
                <Freya data={freyaData4} />
                <Button
                  link={buttons.shows_cta}
                  size="alone"
                  color="gris"
                  text={buttons.shows_ctaText}
                />
              </div>
              <Gerd pageContext={this.props.pageContext} modal={this.state.showModal} />
              <div className="last-grey-content">
                <H2 data={freyaData5} />
                <Freya data={freyaData5} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ZoneDetails;

export const pageQuery = graphql`
  query WorldDetails($locale: String!, $mundo: String, $located: String!) {
    allWorldDetailsSeoData(filter: { lang: { eq: $locale }, tag: { eq: $mundo } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allWorldDetailsHeaderBlock(filter: { lang: { eq: $locale }, tag: { eq: $mundo } }) {
      edges {
        node {
          title
          description
          subtitle
        }
      }
    }
    allWorldDetailsImageHeaderBlock(filter: { tag: { eq: $mundo } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allWorldDetailsBreadCrumb(filter: { lang: { eq: $locale }, tag: { eq: $mundo } }) {
      edges {
        node {
          name
          destination_url
        }
      }
    }
    allWorldDetailsInfo(filter: { lang: { eq: $locale }, tag: { eq: $mundo } }) {
      edges {
        node {
          rides_title
          rides_description
          rides_cta
          rides_ctaText
          restaurants_title
          restaurants_description
          restaurants_cta
          restaurants_ctaText
          shops_title
          shops_description
          shops_cta
          shops_ctaText
          shows_title
          shows_description
          shows_cta
          shows_ctaText
        }
      }
    }
    allTiendasCardsSubBlock(
      filter: { park: { eq: "PortAventura" }, lang: { eq: $locale }, located: { eq: $located } }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imgsize
          image_alt
          title
          description
          seeMore
          located
          features
          disabled
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }

    allRidesSubBlock(
      filter: { park: { eq: "PortAventura" }, lang: { eq: $locale }, located: { eq: $located } }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          imgsize
          title
          description
          seeMore
          located
          features
          disabled
          possibleFilters1
          possibleFilters2
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allRestaurantsSubBlock(
      filter: { park: { eq: "PortAventura" }, lang: { eq: $locale }, located: { eq: $located } }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          imgsize
          title
          description
          seeMore
          located
          features
          disabled
          possibleFilters1
          possibleFilters2
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allShowsSubBlock(
      filter: {
        park: { eq: "PortAventuraShows" }
        lang: { eq: $locale }
        located: { eq: $located }
      }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          imgsize
          title
          description
          seeMore
          located
          features
          disabled
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allWorldDetailsFreya(filter: { lang: { eq: $locale }, tag: { eq: $mundo } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          imgsize
          title
          description
          summary
          position
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allWorldDetailsRichData(filter: { tag: { eq: $mundo }, lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
  }
`;
