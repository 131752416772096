import './Tyr.scss';

import React from 'react';
import { TyrCard } from './TyrCard/TyrCard';

const Tyr = props => {
  const {
    data: { cards },
  } = props;
  return (
    <div className="breadcrumb-container">
      <div className="Tyr-container">
        {cards.map(card => (
          <TyrCard
            key={card.Title}
            MainImage={card.MainImage}
            Image={card.Image}
            alt={card.altImage}
            Text={card.Text}
            Title={card.Title}
            TitleURL={card.TitleURL}
            button1={card.button1}
            button2={card.button2}
          />
        ))}
      </div>
    </div>
  );
};

export default Tyr;
